import { GatsbyImage } from 'gatsby-plugin-image';
import React from 'react';

const FanGoGatsbyImage = ({
  image,
  altText,
  width = '100%',
  boxShadow = '3px 3px 10px 1px grey',
  borderRadius = '20px',
}) => {
  return (
    <GatsbyImage
      image={image}
      alt={altText}
      style={{
        width: width,
        borderRadius: borderRadius,
        boxShadow: boxShadow,
      }}
    />
  );
};

export default FanGoGatsbyImage;
