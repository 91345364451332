import React from 'react';
import GettingStartedPoint from './GettingStartedPoint';
import { Box } from '@mui/material';
import Body from '../../common/body/Body';
import DemoButton from '../../common/button/DemoButton';
import styles from '../../common/styling/styles';

const GettingStartedMenu = ({ data }) => {
  return (
    <Box sx={{ py: styles.allTen, background: 'white' }}>
      {data.map((point, index) => {
        return (
          <Body paddingY={styles.allTwo} key={index}>
            <GettingStartedPoint
              key={index}
              data={point}
              index={index}
              lastItem={index === data.length - 1}
            />
          </Body>
        );
      })}

      <Box sx={{ textAlign: 'center', backgroundColor: 'white', py: 5 }}>
        <DemoButton buttonName="Request a Demo" fontSize={25} />
      </Box>
    </Box>
  );
};

export default GettingStartedMenu;
