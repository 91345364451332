import { Divider, Grid, Hidden, Typography } from '@mui/material';
import { createStyles, makeStyles } from '@mui/styles';
import React from 'react';

import styles from '../../common/styling/styles';
import { MainText } from '../../common/texts';
import FanGoGatsbyImage from '../../common/images/FanGoGatsbyImage';

const mobileHeaderStyle = {
  mb: 5,
  display: styles.displayStyleBBNN,
};

const useStyles = makeStyles(() =>
  createStyles({
    imgClass: {
      borderRadius: '15px',
      opacity: 0.5,
    },
  })
);

const GettingStartedPoint = ({ data, index, lastItem }) => {
  const classes = useStyles();
  const { title, text, image } = data;

  const alternate = (index + 1) % 2 === 0;

  const { asset, altText } = image;
  const selectImage = asset.gatsbyImageData;

  return (
    <>
      <Hidden mdUp>
        <MainText
          fontColor={styles.darkBlue}
          textVariant="h2"
          value={title}
          textAlignment={styles.alignStyleCCCC}
          paddingX={styles.allZero}
          marginY={styles.allOne}
        />
      </Hidden>
      <Grid
        container
        rowSpacing={2}
        columnSpacing={5}
        alignItems="center"
        justifyContent="center"
        sx={[
          {
            flexDirection: {
              md: alternate ? 'row-reverse' : 'row',
              xs: 'column',
            },
          },
        ]}
      >
        <Grid item xs={12} md={6}>
          <FanGoGatsbyImage image={selectImage} altText={altText} />
        </Grid>
        <Grid item xs={12} md={6}>
          <Hidden mdDown>
            <Typography
              variant="h2"
              sx={{
                color: styles.darkBlue,
                textAlign: 'left',
              }}
            >
              {title}
            </Typography>
          </Hidden>
          <Typography variant="body1" sx={{ color: styles.grey }}>
            {text}
          </Typography>
        </Grid>
      </Grid>

      {!lastItem && <Divider sx={{ my: 5, border: 'none' }} />}
    </>
  );
};

export default GettingStartedPoint;

{
  /* <Box sx={mobileHeaderStyle}>
<MainText fontColor={styles.darkBlue} textVariant="h2" value={title} />
</Box>
<Box
sx={{
  display: { md: 'grid', xs: 'block' },
  gridTemplateColumns: '2fr 2fr',
  columnGap: 5,
}}
>
{!alternate && (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    }}
  >
    <GatsbyImage
      image={selectImage}
      alt={altText}
      style={{
        width: '100%',
        borderRadius: '15px',
      }}
      objectFit="contain"
      imgStyle={{ borderRadius: '15px' }}
    />
  </Box>
)}

<TextBody title={title} text={text} index={index} />

{alternate && (
  <Box
    sx={{
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
    }}
  >
    <GatsbyImage
      image={selectImage}
      alt={altText}
      style={{
        width: '100%',
        borderRadius: '15px',
      }}
      objectFit="contain"
      imgStyle={{ borderRadius: '15px' }}
    />
  </Box>
)}
</Box> */
}
